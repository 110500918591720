import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { useAuthActions } from 'src/hooks/useAuthActions';
import { useToast } from 'src/hooks/use-toast';
import loginImg from '../../assets/images/login_img.jpg';

const Login = () => {
  const { login, logout } = useAuthActions();
  const { toast } = useToast();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    logout();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      setApiError('');

      const loginData = {
        email,
        password,
      };

      await login(loginData);
    } catch (error) {
      console.error('Login error:', error);
      const errorMessage =
        error.message || 'An error occurred during login. Please try again.';
      setApiError(errorMessage);

      toast({
        title: 'Error',
        description: errorMessage,
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="h-screen bg-gray-100 flex overflow-hidden">
      {/* Left Column - Image */}
      <div className="hidden md:block w-[68%] relative">
        <div
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${loginImg})`,
            opacity: '0.3',
          }}
        />
        <div className="absolute inset-0 bg-black bg-opacity-30" />
        <div className="relative z-10 h-full flex flex-col justify-center px-16">
          <h1 className="text-4xl font-bold mb-4 ahDarkBlueText">
            Welcome Back to Our Platform
          </h1>
          <p className="font-medium text-gray-800 px-8">
            Sign in to access your account and continue your journey with us.
          </p>
        </div>
      </div>

      {/* Right Column - Form */}
      <div className="w-full md:w-[32%] flex justify-center items-center p-6 overflow-y-auto">
        <div className="w-full max-w-lg ">
          <h2 className="text-2xl font-bold mb-8">Login</h2>

          <form onSubmit={handleSubmit} className="space-y-6">
            {apiError && (
              <div className="rounded-md bg-destructive/15 px-4 py-3 text-sm text-destructive">
                {apiError}
              </div>
            )}

            <div className="space-y-4">
              <Input
                id="email"
                type="email"
                placeholder="me@example.com"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading}
                className={apiError ? 'border-red-500' : ''}
              />
              <div className="space-y-1">
                <Input
                  id="password"
                  type="password"
                  placeholder="Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={isLoading}
                  className={apiError ? 'border-red-500' : ''}
                />
                <div className="flex justify-end">
                  <Link
                    to="/forgot-password"
                    className="text-sm text-blue-600 hover:text-blue-800 transition-colors duration-200"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>

              <Button
                type="submit"
                className="w-full h-12 text-base"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-t-transparent rounded-full" />
                    Logging in...
                  </div>
                ) : (
                  'Login'
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
