import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header/Header';

const LoginLayout = () => (
  <div className="flex h-screen overflow-hidden">
    <div className="flex flex-1 flex-col overflow-hidden">
      <Header />
      <main className="flex flex-1 flex-col overflow-y-auto">
        <Outlet />
      </main>
    </div>
  </div>
);

export default LoginLayout;
