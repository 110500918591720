// src/config/navigation.js
import {
  UsersIcon,
  Squares2X2Icon,
  ClipboardDocumentListIcon,
  ChartBarIcon,
  CurrencyDollarIcon,
  UserCircleIcon,
  AcademicCapIcon,
  ClipboardDocumentCheckIcon,
} from '@heroicons/react/24/outline';

export const navigation = [
  {
    name: 'Staffing',
    href: '#',
    icon: UsersIcon,
    current: true,
    subItems: [
      {
        name: 'Dashboard',
        href: ['/dashboard'],
        icon: Squares2X2Icon,
        current: true,
      },
      {
        name: 'Requirements',
        href: ['/requirements', '/requirements/basic', '/profile'],
        icon: ClipboardDocumentListIcon,
        current: false,
      },
      {
        name: 'Reports',
        href: ['/reports'],
        icon: ChartBarIcon,
        current: false,
      },
      {
        name: 'Billings',
        href: ['/invoice', '/invoice/details'],
        icon: CurrencyDollarIcon,
        current: false,
      },
      {
        name: 'Industry Watch',
        href: ['/industry-watch'],
        icon: UserCircleIcon,
        current: false,
      },
    ],
  },
  {
    name: 'Skilling',
    href: ['#'],
    icon: AcademicCapIcon,
    current: false,
    comingSoon: true,
  },
  {
    name: 'Certification',
    href: ['#'],
    icon: ClipboardDocumentCheckIcon,
    current: false,
    comingSoon: true,
  },
];
