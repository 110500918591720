import { useState, useEffect, useMemo } from 'react';
import { RequirementService } from 'src/services/api/RequirementService';

export const useRequirementData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [masterData, setMasterData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const refData = {
          reference_types: [
            'locations',
            'salary_min_range',
            'salary_max_range',
            'employment_type',
            'work_experience',
            'education',
            'field_of_study',
            'skills',
            'certifications',
            'regulatory_knowledge',
            'job_titles',
            'business_units',
            'career_level',
            'personas',
          ],
        };
        const response = await RequirementService.getReferenceData(refData);

        if (response.success) {
          setMasterData(response.data);
        } else {
          throw new Error(response.message || 'Failed to fetch master data');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const formattedOptions = useMemo(() => {
    if (!masterData) return {};

    const formatData = (data, valueKey = 'value', labelKey = 'name') =>
      data?.map((item) => ({
        value: item[valueKey]?.toString(),
        label: item[labelKey] || item[valueKey],
      })) || [];
    const formatBusinessUnits = (data) =>
      data?.map((item) => ({
        value: item.id.toString(), // Convert ID to string for consistency
        label: item.value, // Use the value field as the label
      })) || [];
    const formatJobTitles = (personas) =>
      personas?.map((persona) => ({
        value: persona.id.toString(),
        label: persona.title,
        description: persona.persona_description,
        customerType: persona.customer_type,
      })) || [];

    return {
      locations: formatData(masterData.locations, 'id', 'value'),
      minSalaryRanges: formatData(masterData.salary_min_range),
      maxSalaryRanges: formatData(masterData.salary_max_range),
      employmentTypes: masterData.employment_type || [],
      workExperiences: formatData(masterData.work_experience, 'id', 'value'),
      educations: formatData(masterData.education, 'id', 'value'),
      fieldOptions: formatData(masterData.field_of_study, 'id', 'name'),
      skillOptions: formatData(masterData.skills, 'id', 'name'),
      certificationOptions: formatData(masterData.certifications, 'id', 'name'),
      regulatoryOptions: formatData(
        masterData.regulatory_knowledge,
        'id',
        'name'
      ),
      jobTitles: formatJobTitles(masterData.personas),
      businessUnits: formatBusinessUnits(masterData.business_units || []),
      careerLevels: formatData(masterData.career_level, 'id', 'value' || []),
    };
  }, [masterData]);

  return {
    isLoading,
    error,
    masterData: formattedOptions,
  };
};
