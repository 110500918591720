import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';
import { Input } from 'src/components/ui/input';
import { Label } from 'src/components/ui/label';
import { Breadcrumb } from 'src/components/common/Breadcrumb';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { RequirementService } from 'src/services/api/RequirementService';

const initialSteps = [
  { id: 'step1', text: 'Initial Screening', checked: false, owner: null },
  { id: 'step2', text: 'Video Interview', checked: false, owner: null },
  { id: 'step3', text: 'Skill Test', checked: false, owner: null },
  { id: 'step4', text: 'Technical Interview', checked: false, owner: null },
  { id: 'step5', text: 'HR Interview', checked: false, owner: null },
  { id: 'step6', text: 'Final Decision', checked: false, owner: null },
];
const getBreadcrumbItems = (id) => [
  { label: 'Home', href: '/' },
  { label: 'Requirements', href: '/requirements' },
  {
    label: 'Basic Info',
    href: `/requirements/basic${id ? `/${id}` : ''}`,
  },
  {
    label: 'Selection Process',
    href: `/requirements/selection${id ? `/${id}` : ''}`,
  },
];
const DraggableStep = React.memo(
  ({ step, index, onCheckboxChange, onOwnerChange }) => (
    <Draggable draggableId={step.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.draggableProps}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.dragHandleProps}
          className={`flex items-center justify-between p-2 bg-gray-100 rounded cursor-move ${
            snapshot.isDragging ? 'bg-gray-200 shadow-lg' : ''
          }`}
        >
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <Checkbox
                id={step.id}
                checked={step.checked}
                onCheckedChange={(checked) => {
                  onCheckboxChange(step.id, checked);
                  // if (!checked) {
                  //   onOwnerChange(step.id, null);
                  // }
                }}
              />
              {/* <Checkbox
                id={step.id}
                checked={step.checked}
                onCheckedChange={(checked) =>
                  onCheckboxChange(step.id, checked)
                }
              /> */}
              <Label htmlFor={step.id} className="text-sm font-medium">
                {step.text}
              </Label>
            </div>
          </div>
          <Select
            value={step.owner || undefined}
            onValueChange={(value) => onOwnerChange(step.id, value)}
            disabled={!step.checked}
          >
            <SelectTrigger
              className={`w-32 ${
                !step.checked
                  ? 'opacity-50 cursor-not-allowed text-gray-400'
                  : 'text-gray-700'
              }`}
            >
              <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="AHEDGE">Ahedge</SelectItem>
              <SelectItem value="CUSTOMER">Customer</SelectItem>
            </SelectContent>
          </Select>
        </div>
      )}
    </Draggable>
  )
);
DraggableStep.displayName = 'DraggableStep';

const transformStepsForApi = (steps) =>
  steps
    .filter((step) => step.checked)
    .map((step, index) => ({
      process_name: step.text,
      order_of_execution: index + 1,
      executed_by_customer: step.owner === 'CUSTOMER',
    }));

const SelectionProcess = () => {
  const { reqId } = useParams();
  const navigate = useNavigate();
  const breadcrumbItems = getBreadcrumbItems(reqId);
  const [steps, setSteps] = useState([]);
  const [newStep, setNewStep] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [validationError, setValidationError] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchSelectionProcess = async () => {
      try {
        setIsLoading(true);
        if (reqId) {
          const response = await RequirementService.getSelectionProcess(reqId);

          if (response.success && response.data.processes.length > 0) {
            // Transform API response to match steps structure
            const transformedSteps = response.data.processes.map((process) => ({
              id: `step${process.id}`,
              text: process.process_name,
              checked: true, // Since these are saved steps, they should be checked
              owner: process.executed_by_customer ? 'CUSTOMER' : 'AHEDGE',
            }));
            setSteps(transformedSteps);
          } else {
            setSteps(initialSteps);
          }
        } else {
          setSteps(initialSteps);
        }
      } catch (error) {
        console.error('Error fetching selection process:', error);
        setSteps(initialSteps);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSelectionProcess();
  }, [reqId]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(steps);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSteps(items);
  };

  const handleCheckboxChange = (stepId, checked) => {
    setValidationError('');

    const updatedSteps = steps.map((step) =>
      step.id === stepId
        ? { ...step, checked, owner: checked ? step.owner : null }
        : step
    );

    setSteps(updatedSteps);
  };
  const handleOwnerChange = (id, owner) => {
    setValidationError(''); // Clear validation error when making changes
    setSteps(steps.map((step) => (step.id === id ? { ...step, owner } : step)));
  };

  const addCustomStep = () => {
    if (newStep.trim()) {
      setSteps([
        ...steps,
        {
          id: `step${steps.length + 1}`,
          text: newStep,
          checked: false,
          owner: null,
        },
      ]);
      setNewStep('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      addCustomStep();
    }
  };

  const handleSkip = () => {
    navigate(`/requirements/preview/${reqId}`);
  };

  const handleBack = () => {
    navigate(`/requirements/basic/${reqId}`);
  };
  const validateSteps = () => {
    const checkedSteps = steps.filter((step) => step.checked);
    if (checkedSteps.length === 0) {
      setValidationError('Please select at least one step');
      return false;
    }

    const invalidSteps = checkedSteps.filter((step) => !step.owner);
    if (invalidSteps.length > 0) {
      setValidationError('Please select an owner for all checked steps');
      return false;
    }

    setValidationError('');
    return true;
  };

  const handleNext = async () => {
    if (!validateSteps()) {
      return;
    }

    try {
      setIsSaving(true);
      // Transform and save the steps
      const transformedSteps = transformStepsForApi(steps);
      const data = {
        requirement_id: reqId, // Use id from useParams
        selection_process: transformedSteps,
      };

      const saveResponse = await RequirementService.saveSelectionProcess(data);

      if (!saveResponse?.success) {
        throw new Error(
          saveResponse?.error || 'Failed to save selection process'
        );
      }

      navigate(`/requirements/preview/${reqId}`);
    } catch (error) {
      setValidationError(error.message || 'Failed to save selection process');
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p>Loading selection process...</p>
      </div>
    );
  }
  // const checkedCount = steps.filter((s) => s.checked).length;
  return (
    <div className="text-left space-y-4">
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl pageTitle">
          Selection Process
        </h1>
      </div>
      <Breadcrumb items={breadcrumbItems} />
      <div className="items-center justify-center mt-6">
        <div className="flex flex-col gap-1 text-center w-full">
          {validationError && (
            <div className="bg-red-50 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
              <span className="block sm:inline">{validationError}</span>
            </div>
          )}
          <div className="text-left space-y-6 bg-white rounded-lg">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="steps">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...provided.droppableProps}
                    className="space-y-2"
                  >
                    {steps.map((step, index) => (
                      <DraggableStep
                        key={step.id}
                        step={step}
                        index={index}
                        onCheckboxChange={handleCheckboxChange}
                        onOwnerChange={handleOwnerChange}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div className="mt-4 flex space-x-2">
              <Input
                value={newStep}
                onChange={(e) => setNewStep(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Enter new step name"
                className="flex-1"
              />
              <Button onClick={addCustomStep} disabled={!newStep.trim()}>
                Add Custom Step
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-6 flex justify-between items-center w-full">
          <div>
            <Button variant="outline" onClick={handleBack}>
              Back
            </Button>
          </div>
          <div className="space-x-2">
            <Button variant="secondary" onClick={handleSkip}>
              Skip
            </Button>
            <Button
              onClick={handleNext}
              disabled={
                isSaving ||
                steps.filter((step) => step.checked).length === 0 ||
                steps.some((step) => step.checked && !step.owner)
              }
            >
              {isSaving ? 'Saving...' : 'Next'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectionProcess;
