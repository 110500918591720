import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import { Breadcrumb } from 'src/components/common/Breadcrumb';
import FilterButtons from 'src/components/common/FilterButton';
import { RequirementService } from 'src/services/api/RequirementService';
import { ContentContainer } from './ContentContainer';

const filters = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Drafts', value: 'DRAFT' },
  { label: 'Archived', value: 'ARCHIVED' },
];

const breadcrumbItems = [
  { label: 'Home', href: '/' },
  { label: 'Requirements', href: '/requirements' },
];

// Separate RequirementsList component
const RequirementsList = ({ isLoading, error, requirements }) => {
  if (isLoading) {
    return (
      <div className="text-center py-8 text-gray-500">
        Loading requirements...
      </div>
    );
  }

  if (error) {
    return <p className="text-red-500 p-4">Error: {error}</p>;
  }

  if (requirements.length === 0) {
    return (
      <div className="text-left py-8 text-gray-500">No requirements found</div>
    );
  }

  return (
    <div className="space-y-3">
      {requirements?.map((item) => (
        <ContentContainer
          key={item.id}
          requirementId={item.id}
          title={item.title}
          date={item.date}
          description={item.description}
          statistics={item.statistics}
          businessUnit={item.businessUnit}
        />
      ))}
    </div>
  );
};

function Requirements() {
  const navigate = useNavigate();
  const [requirements, setRequirements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentFilter, setCurrentFilter] = useState('ACTIVE');

  useEffect(() => {
    const fetchRequirements = async () => {
      try {
        setIsLoading(true);
        const response = await RequirementService.getRequirementByStatus({
          id: 1,
          status: currentFilter,
        });

        if (response?.success) {
          const transformRequirement = (req) => ({
            id: req.id,
            title: req.title,
            date: new Date(req.created_at).toLocaleDateString(),
            businessUnit: req.business_unit,
            description: '',
            statistics: {
              required: req.numbers_required,
              offersMade: req.offers_made,
              profilesSubmitted: req.profiles_submitted,
              closingDate: new Date(req.created_at).toLocaleDateString(),
            },
          });

          if (Array.isArray(response?.data?.requirements?.items)) {
            const transformedRequirements =
              response.data.requirements.items.map(transformRequirement);
            setRequirements(transformedRequirements);
          } else {
            setRequirements([]);
          }
        } else {
          throw new Error(response.error || 'Failed to fetch requirements');
        }
      } catch (err) {
        setError(err.message);
        console.error('Error fetching requirements:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRequirements();
  }, [currentFilter]);

  const handleClick = () => {
    navigate('/requirements/basic');
  };

  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);
  };

  return (
    <div className="text-left space-y-4">
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl pageTitle">
          Requirements
        </h1>
      </div>
      <Breadcrumb items={breadcrumbItems} />
      <div className="flex justify-between items-center pb-4 md:pb-6 lg:pb-0">
        <FilterButtons
          filters={filters}
          defaultFilter={currentFilter}
          onFilterChange={handleFilterChange}
        />
        <div>
          <Button className="btn-ahedge-outline" onClick={handleClick}>
            Create New
          </Button>
        </div>
      </div>

      <div className="items-center justify-center rounded-lg shadow-sm min-h-[200px]">
        <RequirementsList
          isLoading={isLoading}
          error={error}
          requirements={requirements}
        />
      </div>
    </div>
  );
}

export default Requirements;
