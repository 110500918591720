import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { Sheet, SheetContent } from 'src/components/ui/sheet';
import { ChevronDown, LogOut, User, Home } from 'lucide-react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { navigation } from 'src/config/navigation';
import logoImg from 'src/assets/images/ahedge_logo.svg';
import { useAuthActions } from 'src/hooks/useAuthActions';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Header() {
  const [expandedItems, setExpandedItems] = React.useState({ Staffing: true });
  const [userData, setUserData] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const { logout } = useAuthActions();

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('token');
      const userString = localStorage.getItem('user');
      const parsedUser = userString ? JSON.parse(userString) : null;
      try {
        setUserData(parsedUser);
        setIsAuthenticated(!!token);
      } catch (error) {
        console.error('Error parsing user data:', error);
        setUserData(null);
        setIsAuthenticated(false);
      }
    };

    checkAuth();
    window.addEventListener('storage', checkAuth);
    return () => window.removeEventListener('storage', checkAuth);
  }, [location]);

  const toggleSubmenu = (itemName) => {
    setExpandedItems((prev) => ({
      ...prev,
      [itemName]: !prev[itemName],
    }));
  };
  const handleLogout = () => {
    logout();
  };
  const isUrlActive = (urlPaths) => {
    const paths = Array.isArray(urlPaths) ? urlPaths : [urlPaths];
    return paths.some((url) => location.pathname.startsWith(url));
  };

  const isSubmenuItemActive = (item) =>
    item.subItems && item.subItems.some((subItem) => isUrlActive(subItem.href));

  const getMenuItemClasses = (
    isMainMenuActive,
    isSubmenuActive,
    isComingSoon
  ) => {
    const baseClasses =
      'flex items-center justify-between gap-3 rounded-lg px-3 py-3 transition-all hover:text-primary';

    if (isComingSoon) {
      return `${baseClasses} cursor-default pointer-events-none opacity-50`;
    }

    if (isMainMenuActive && !isSubmenuActive) {
      return `${baseClasses} bg-menuhover text-primary`;
    }

    if (isSubmenuActive) {
      return `${baseClasses} text-primary`;
    }

    return `${baseClasses} text-muted-foreground`;
  };

  const renderMenuItem = (item) => {
    const isMainMenuActive = isUrlActive(item.href);
    const isSubmenuActive = isSubmenuItemActive(item);
    const isExpanded = expandedItems[item.name];

    return (
      <div key={item.name}>
        <Link
          to={Array.isArray(item.href) ? item.href[0] : item.href}
          className={getMenuItemClasses(
            isMainMenuActive,
            isSubmenuActive,
            item.comingSoon
          )}
          onClick={(e) => {
            if (item.subItems || item.comingSoon) {
              e.preventDefault();
              if (item.subItems) {
                toggleSubmenu(item.name);
              }
            }
          }}
        >
          <div className="flex items-center gap-3">
            <item.icon className="h-4 w-4" />
            {item.name}
          </div>
          {item.comingSoon && (
            <span className="text-[10px] bg-yellow-200 text-yellow-800 px-1 py-0.5 rounded leading-none">
              Coming Soon
            </span>
          )}
          {item.subItems &&
            (isExpanded ? (
              <ChevronUpIcon className="h-4 w-4" />
            ) : (
              <ChevronDownIcon className="h-4 w-4" />
            ))}
        </Link>
        {item.subItems && isExpanded && (
          <div className="ml-6 mt-2 space-y-1">
            {item.subItems.map((subItem) => (
              <Link
                key={subItem.name}
                to={
                  Array.isArray(subItem.href) ? subItem.href[0] : subItem.href
                }
                className={classNames(
                  isUrlActive(subItem.href)
                    ? 'bg-menuhover text-primary'
                    : 'text-muted-foreground hover:text-primary',
                  'flex items-center gap-2 rounded-md px-3 py-2 text-sm'
                )}
              >
                <subItem.icon className="h-4 w-4" />
                {subItem.name}
              </Link>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <header className="flex h-14 items-center justify-between headerBg px-4 lg:h-[60px] lg:px-6">
      <div className="flex items-center gap-4">
        <Sheet>
          <SheetContent side="left" className="w-[300px] sm:w-[400px]">
            <nav className="grid gap-2 text-lg font-medium">
              {/* <Link
                to="/"
                className="flex items-center gap-2 font-semibold mb-4"
              >
                <img src={logoImg} alt="Logo" className="h-8 w-auto" />
              </Link> */}
              {navigation.map(renderMenuItem)}
            </nav>
          </SheetContent>
        </Sheet>
        <Link to="/" className="flex items-center gap-2 font-semibold">
          <img src={logoImg} alt="Logo" className="h-full w-full object-none" />
        </Link>
      </div>

      {/* User Greeting */}
      {isAuthenticated && userData && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="ghost"
              size="sm"
              className="text-white flex items-center gap-2"
            >
              <User className="h-4 w-4" />
              <span>{userData.firstName}</span>
              <ChevronDown className="h-3 w-3 opacity-50" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-[200px]">
            <DropdownMenuItem asChild>
              <Link to="/requirements" className="flex items-center gap-2">
                <Home className="h-4 w-4" />
                <span>Home</span>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Link to="/change-password" className="flex items-center gap-2">
                <User className="h-4 w-4" />
                <span>Change Password</span>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={handleLogout}>
              <LogOut className="h-4 w-4 mr-2" />
              <span>Logout</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </header>
  );
}
