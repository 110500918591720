import React from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'src/components/ui/card';
import { Button } from 'src/components/ui/button';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { Badge } from 'src/components/ui/badge';
import { Breadcrumb } from 'src/components/common/Breadcrumb';
import logoImg1 from '../../../assets/images/blog1.png';
import logoImg2 from '../../../assets/images/blog2.png';
import logoImg3 from '../../../assets/images/blog3.png';
import logoImg4 from '../../../assets/images/blog4.png';

const BlogPostCard = ({ title, excerpt, author, date, category, imageUrl }) => (
  <Card className="mb-6">
    <div className="md:flex">
      <div className="md:w-1/3">
        <img
          src={imageUrl}
          alt={title}
          className="w-full h-48 md:h-full object-cover rounded-t-lg md:rounded-l-lg md:rounded-t-none"
        />
      </div>
      <div className="md:w-2/3 p-6">
        <CardHeader className="p-0">
          <div className="flex justify-between items-center mb-2">
            <Badge variant="secondary">{category}</Badge>
            <CardDescription>{date}</CardDescription>
          </div>
          <CardTitle className="text-lg mb-2 text-left">{title}</CardTitle>
        </CardHeader>
        <CardContent className="p-0 mb-4 text-left">
          <p className="text-gray-600">{excerpt}</p>
        </CardContent>
        <CardFooter className="p-0 flex justify-between items-center">
          <div className="flex items-center">
            <Avatar className="h-8 w-8 mr-2">
              <AvatarImage src={author.avatar} alt={author.name} />
              <AvatarFallback>{author.name.charAt(0)}</AvatarFallback>
            </Avatar>
            <span className="text-sm text-gray-600">{author.name}</span>
          </div>
          <Button variant="outline">Read More</Button>
        </CardFooter>
      </div>
    </div>
  </Card>
);

const BlogListing = () => {
  const breadcrumbItems = [
    { label: 'Home', href: '/' },
    { label: 'Industry Watch', href: '/industry-watch' },
  ];
  const blogPosts = [
    {
      id: '1',
      title: '5 Emerging Cybersecurity Risks in Financial Services',
      excerpt:
        'Explore the latest cybersecurity threats facing financial institutions in 2024, including AI-powered attacks, quantum computing vulnerabilities, and decentralized finance risks.',
      author: { name: 'Rajesh Sharma', avatar: '/api/placeholder/40/40' },
      date: 'September 24, 2024',
      category: 'Cybersecurity',
      imageUrl: logoImg1,
    },
    {
      id: '2',
      title: 'Climate Change and Supply Chain Risk: What You Need to Know',
      excerpt:
        'Discover how global warming is reshaping supply chain risks and learn strategies to build resilience against climate-related disruptions in your business operations.',
      author: { name: 'Priya Patel', avatar: '/api/placeholder/40/40' },
      date: 'September 22, 2024',
      category: 'Supply Chain',
      imageUrl: logoImg2,
    },
    {
      id: '3',
      title: 'Navigating Geopolitical Risks in a Multipolar World',
      excerpt:
        'Understand the complexities of geopolitical risk assessment and develop effective mitigation strategies for businesses operating in volatile international markets.',
      author: { name: 'Vikram Mehta', avatar: '/api/placeholder/40/40' },
      date: 'September 20, 2024',
      category: 'Geopolitics',
      imageUrl: logoImg3,
    },
    {
      id: '4',
      title: 'The Rise of ESG Risks: Implications for Modern Businesses',
      excerpt:
        'Delve into the growing importance of Environmental, Social, and Governance (ESG) risks and their potential impact on company valuation, reputation, and regulatory compliance.',
      author: { name: 'Anjali Desai', avatar: '/api/placeholder/40/40' },
      date: 'September 18, 2024',
      category: 'ESG',
      imageUrl: logoImg4,
    },
    {
      id: '5',
      title: 'AI in Risk Management: Opportunities and Ethical Challenges',
      excerpt:
        'Examine how AI is revolutionizing risk assessment across industries, from insurance underwriting to credit scoring, and the ethical considerations that come with it.',
      author: { name: 'Arjun Reddy', avatar: '/api/placeholder/40/40' },
      date: 'September 16, 2024',
      category: 'Artificial Intelligence',
      imageUrl: logoImg1,
    },
    {
      id: '6',
      title: 'Pandemic Preparedness: Lessons for Future Crisis Management',
      excerpt:
        'Reflect on the risk management lessons from recent global health crises and how organizations can better prepare for future pandemics and similar large-scale disruptions.',
      author: { name: 'Dr. Sunita Rao', avatar: '/api/placeholder/40/40' },
      date: 'September 14, 2024',
      category: 'Crisis Management',
      imageUrl: logoImg3,
    },
    {
      id: '7',
      title: 'Cryptocurrency Risks: Navigating the Digital Asset Landscape',
      excerpt:
        'Analyze the unique risks associated with digital assets and blockchain technology, including regulatory uncertainty, market volatility, and technological vulnerabilities.',
      author: { name: 'Aditya Choudhury', avatar: '/api/placeholder/40/40' },
      date: 'September 12, 2024',
      category: 'Cryptocurrency',
      imageUrl: logoImg2,
    },
    {
      id: '8',
      title: 'Operational Risk in the Age of Digital Transformation',
      excerpt:
        'Explore how digital transformation is changing the landscape of operational risk and learn strategies to manage risks associated with new technologies and processes.',
      author: { name: 'Neha Gupta', avatar: '/api/placeholder/40/40' },
      date: 'September 10, 2024',
      category: 'Operational Risk',
      imageUrl: logoImg4,
    },
  ];

  return (
    <div className="text-left space-y-4">
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl pageTitle">
          Industry Watch
        </h1>
      </div>
      <Breadcrumb items={breadcrumbItems} />

      <div className="items-center justify-center rounded-lg  shadow-sm">
        <div className="space-y-6">
          {blogPosts.map((post) => (
            <BlogPostCard
              key={post.id}
              title={post.title}
              excerpt={post.excerpt}
              author={post.author}
              date={post.date}
              category={post.category}
              imageUrl={post.imageUrl}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogListing;
