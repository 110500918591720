import React, { useState, useEffect } from 'react';
import { Button } from 'src/components/ui/button';

const FilterButton = ({ filters, onFilterChange, defaultActiveFilter }) => {
  const [activeFilter, setActiveFilter] = useState(
    defaultActiveFilter || filters[0]?.value
  );

  useEffect(() => {
    if (defaultActiveFilter) {
      setActiveFilter(defaultActiveFilter);
    }
  }, [defaultActiveFilter]);

  const handleFilterClick = (filterValue) => {
    setActiveFilter(filterValue);
    onFilterChange(filterValue);
  };

  return (
    // <div className="space-x-2">
    //   {filters.map((filter) => (
    //     <Button
    //       key={filter.value}
    //       variant={activeFilter === filter.value ? 'default' : 'outline'}
    //       className={activeFilter !== filter.value ? 'btn-ahedge-outline' : ''}
    //       onClick={() => handleFilterClick(filter.value)}
    //     >
    //       {filter.label}
    //     </Button>
    //   ))}
    // </div>
    <div className="w-full">
      <div className="flex items-center">
        <div className="flex space-x-2">
          {filters.map((filter) => (
            <Button
              key={filter.value}
              variant={activeFilter === filter.value ? 'default' : 'outline'}
              className={`
                px-4 py-2
                border
                transition-colors duration-200
                ${
                  activeFilter === filter.value
                    ? 'border-primary bg-primary text-primary-foreground hover:bg-primary/90'
                    : 'border-primary bg-background text-primary hover:bg-primary/10'
                }
              `}
              onClick={() => handleFilterClick(filter.value)}
            >
              {filter.label}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterButton;
