import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { Button } from 'src/components/ui/button';
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from 'lucide-react';

const PAGE_SIZES = [5, 10, 20, 30, 50, 100];

export const DataTablePagination = ({
  currentPage = 1,
  pageSize = 10,
  totalItems = 0,
  onPageChange,
  onPageSizeChange,
  showPageSize = true,
  preserveParams = true, // whether to preserve other URL params
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const totalPages = Math.ceil(totalItems / pageSize);
  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math.min(currentPage * pageSize, totalItems);

  const updateUrlParams = (newParams) => {
    const updatedParams = new URLSearchParams(
      preserveParams ? searchParams : {}
    );

    // Update with new params
    Object.entries(newParams).forEach(([key, value]) => {
      if (value) {
        updatedParams.set(key, value.toString());
      } else {
        updatedParams.delete(key);
      }
    });

    setSearchParams(updatedParams);
  };

  const handlePageSizeChange = (newSize) => {
    const intSize = parseInt(newSize, 10);

    // Update URL
    updateUrlParams({
      pageSize: intSize,
      page: 1, // Reset to first page when changing page size
    });

    // Notify parent
    onPageSizeChange?.(intSize);
  };

  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      // Update URL
      updateUrlParams({ page });

      // Notify parent
      onPageChange?.(page);
    }
  };

  const renderPageButtons = () => {
    const buttons = [];

    // Previous page buttons
    if (currentPage > 2) {
      buttons.push(
        <Button
          key="prev-2"
          variant="outline"
          size="icon"
          className="h-8 w-8"
          onClick={() => goToPage(currentPage - 2)}
        >
          {currentPage - 2}
        </Button>
      );
    }

    if (currentPage > 1) {
      buttons.push(
        <Button
          key="prev-1"
          variant="outline"
          size="icon"
          className="h-8 w-8"
          onClick={() => goToPage(currentPage - 1)}
        >
          {currentPage - 1}
        </Button>
      );
    }

    // Current page
    buttons.push(
      <Button
        key="current"
        variant="outline"
        size="icon"
        className="h-8 w-8 bg-primary text-primary-foreground hover:bg-primary/90"
      >
        {currentPage}
      </Button>
    );

    // Next page buttons
    if (currentPage < totalPages) {
      buttons.push(
        <Button
          key="next-1"
          variant="outline"
          size="icon"
          className="h-8 w-8"
          onClick={() => goToPage(currentPage + 1)}
        >
          {currentPage + 1}
        </Button>
      );
    }

    if (currentPage < totalPages - 1) {
      buttons.push(
        <Button
          key="next-2"
          variant="outline"
          size="icon"
          className="h-8 w-8"
          onClick={() => goToPage(currentPage + 2)}
        >
          {currentPage + 2}
        </Button>
      );
    }

    return buttons;
  };

  return (
    <div className="flex flex-col md:flex-row items-center justify-between gap-4 py-2">
      <div className="flex items-center gap-2 text-sm text-muted-foreground">
        {showPageSize && (
          <div className="flex items-center gap-2">
            <p>Rows per page</p>
            <Select
              value={pageSize.toString()}
              onValueChange={handlePageSizeChange}
            >
              <SelectTrigger className="h-8 w-[70px]">
                <SelectValue placeholder={pageSize} />
              </SelectTrigger>
              <SelectContent>
                {PAGE_SIZES.map((size) => (
                  <SelectItem key={size} value={size.toString()}>
                    {size}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}
      </div>

      <div className="flex flex-col md:flex-row items-center gap-2 lg:gap-8">
        <p className="text-sm text-muted-foreground">
          Showing {totalItems > 0 ? startItem : 0} to {endItem} of {totalItems}{' '}
          entries
        </p>

        <div className="flex items-center gap-1">
          <Button
            variant="outline"
            size="icon"
            className="h-8 w-8"
            onClick={() => goToPage(1)}
            disabled={currentPage <= 1}
          >
            <ChevronsLeft className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            size="icon"
            className="h-8 w-8"
            onClick={() => goToPage(currentPage - 1)}
            disabled={currentPage <= 1}
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>

          <div className="flex items-center gap-1">{renderPageButtons()}</div>

          <Button
            variant="outline"
            size="icon"
            className="h-8 w-8"
            onClick={() => goToPage(currentPage + 1)}
            disabled={currentPage >= totalPages}
          >
            <ChevronRight className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            size="icon"
            className="h-8 w-8"
            onClick={() => goToPage(totalPages)}
            disabled={currentPage >= totalPages}
          >
            <ChevronsRight className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  );
};
