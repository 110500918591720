import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header/Header';
import CustomSidebar from '../Sidebar/CustomerSidebar';

const CustomerLayout = () => (
  <div className="h-screen overflow-hidden flex flex-col">
    <Header />
    <div className="flex flex-1 overflow-hidden">
      <CustomSidebar />
      <main className="flex-1 overflow-y-auto p-4 lg:gap-4 lg:p-4 border-l scrollbar-hide">
        <Outlet />
      </main>
    </div>
  </div>
);

export default CustomerLayout;
