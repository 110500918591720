import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import { Users, Handshake, FileText, CalendarX } from 'lucide-react';

export function ContentContainer({
  requirementId,
  title,
  date,
  statistics,
  businessUnit,
}) {
  const navigate = useNavigate();

  const handleOffersClick = () => {
    navigate(
      `/requirements/candidates?status=offer_extended&reqId=${requirementId}`
    );
  };

  const handleProfilesClick = () => {
    navigate(`/requirements/candidates?status=new&reqId=${requirementId}`);
  };

  const handleViewClick = () => {
    navigate(`/requirements/preview/${requirementId}`);
  };

  return (
    <div className="mx-auto p-0 border rounded-lg mb-4 text-left">
      <div className="p-4">
        <h4 className="font-bold">{title}</h4>
        <div className="flex flex-col mb-2">
          <span className="text-sm">Business Unit: {businessUnit}</span>
          <span className="text-xs text-muted-foreground">
            Posted on: {date}
          </span>
        </div>
      </div>
      <div className="p-4 lightBlueBg border-t rounded-b-lg">
        <div className="flex flex-col space-y-4">
          <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-[1fr_1fr_1fr_auto_auto] gap-4 items-center">
            <Button
              variant="link"
              className="p-0 h-auto text-sm no-underline hover:no-underline cursor-default justify-start"
            >
              <Users className="w-4 h-4 mr-1 flex-shrink-0" />
              <span className="text-left font-semibold">
                Required: {statistics.required}
              </span>
            </Button>
            <Button
              variant="link"
              className="p-0 h-auto text-sm no-underline hover:no-underline justify-start"
              onClick={handleOffersClick}
            >
              <Handshake className="w-4 h-4 mr-1 flex-shrink-0" />
              <span className="text-left font-semibold">
                Offers Made: {statistics.offersMade}
              </span>
            </Button>
            <Button
              variant="link"
              className="p-0 h-auto text-sm no-underline hover:no-underline justify-start"
              onClick={handleProfilesClick}
            >
              <FileText className="w-4 h-4 mr-1 flex-shrink-0" />
              <span className="text-left font-semibold">
                Profiles Submitted: {statistics.profilesSubmitted}
              </span>
            </Button>
            <div className="flex items-center text-sm text-muted-foreground whitespace-nowrap justify-start">
              <CalendarX className="w-4 h-4 mr-1 flex-shrink-0" />
              <span className="text-left">
                Closing Date: {statistics.closingDate}
              </span>
            </div>
            <div className="flex justify-end">
              <Button
                variant="outline"
                className="border-primary bg-background text-primary hover:bg-primary/10 h-8 w-24"
                onClick={handleViewClick}
              >
                View
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
