import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
// Import your page components
import CustomerLayout from '../../layouts/MainLayout/CustomerLayout';
import LoginLayout from '../../layouts/MainLayout/LoginLayout';
import Login from '../../pages/Login/Login';
import Signup from '../../pages/Signup/Signup';
import Requirements from '../../pages/Customer/Requirements/Requirements';
import NewRequirement from '../../pages/Customer/NewRequirement/NewRequirement';
import SelectionProcess from '../../pages/Customer/NewRequirement/SelectionProcess';
import RequirementPreview from '../../pages/Customer/NewRequirement/RequirementPreview';
import ProfileListing from '../../pages/Customer/Profile/ProfileListing';
import InvoiceList from '../../pages/Customer/Invoice/InvoiceListing';
import InvoiceDetails from '../../pages/Customer/InvoiceDetails/InvoiceDetails';
import ProfileView from '../../pages/Customer/Profile/ProfileView';
import BlogListing from '../../pages/Customer/IndustryWatch/BlogListing';
import Dashboard from '../../pages/Customer/Dashboard/Dashboard';
import Reports from '../../pages/Customer/Reports/Reports';
import ListByFilter from '../../pages/Customer/ListByFilter/ListByFilter';
import NotFound from '../../pages/NotFound/NotFound';

const AppRouter = () => (
  <BrowserRouter>
    <Routes>
      {/* Public routes */}
      <Route path="/" element={<LoginLayout />}>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
      </Route>
      {/* {user && user.role === "customer" ? ( */}
      <Route path="/" element={<CustomerLayout />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/requirements" element={<Requirements />} />
        <Route
          path="/requirements/basic/:reqId?"
          element={<NewRequirement />}
        />
        <Route
          path="/requirements/selection/:reqId"
          element={<SelectionProcess />}
        />
        <Route
          path="/requirements/preview/:reqId"
          element={<RequirementPreview />}
        />
        <Route path="/profile" element={<ProfileListing />} />
        <Route
          path="/requirements/candidate/:profileId"
          element={<ProfileView />}
        />
        <Route path="/invoice" element={<InvoiceList />} />
        <Route path="/invoice/:invoiceId" element={<InvoiceDetails />} />
        <Route path="/industry-watch" element={<BlogListing />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/requirements/candidates" element={<ListByFilter />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default AppRouter;
