import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { RequirementService } from 'src/services/api/RequirementService';

export const useRequirementForm = ({
  defaultValues,
  onSubmit,
  reqId,
  masterData,
}) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('initial');
  const [newPersona, setNewPersona] = useState(null);
  const [proposedJobTitle, setProposedJobTitle] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const form = useForm({ defaultValues });
  const { handleSubmit, setValue, watch } = form;

  const currentJobTitle = watch('jobTitle', '');

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
    }
  }, [defaultValues, form]);

  const fetchAndPopulateTemplate = async (jobTitleId) => {
    try {
      const response = await RequirementService.getPersonaInfo(jobTitleId);
      const templateData = response.data;

      // Basic fields
      setValue('description', templateData.detailed_job_description);
      setValue('employmentType', 'FULL_TIME');
      setValue('minSalary', templateData.minimum_salary.toString());
      setValue('maxSalary', templateData.minimum_salary.toString());
      setValue('minExperience', templateData.work_experience_id.toString());
      setValue('careerLevel', templateData.career_level);

      // Qualifications
      if (templateData.qualifications?.[0]) {
        setValue(
          'minEducation',
          templateData.qualifications[0].qualification_type
        );
        const mappedFields = templateData.qualifications[0].field_of_study.map(
          (field) => ({
            value: field.field_of_study_id.toString(),
            isMandatory: field.is_mandatory,
          })
        );
        setValue('fieldsOfStudy', mappedFields);
      }

      // Skills
      const mappedSkills = templateData.skills.map((skill) => ({
        value: skill.id.toString(),
        isMandatory: skill.is_mandatory,
      }));
      setValue('skills', mappedSkills);

      // Certifications
      const mappedCerts = templateData.certifications.map((cert) => ({
        value: cert.id.toString(),
        isMandatory: cert.is_mandatory,
      }));
      setValue('professionalCertifications', mappedCerts);
    } catch (error) {
      console.error('Error fetching template:', error);
    }
  };

  // const handleRoleChange = async (newValue) => {
  //   if (!currentJobTitle) {
  //     setValue('jobTitle', newValue);
  //     await fetchAndPopulateTemplate(newValue);
  //   } else if (currentJobTitle !== newValue) {
  //     setProposedJobTitle(newValue);
  //     setIsAlertOpen(true);
  //   }
  // };
  const handleRoleChange = async (newValue) => {
    // Early return if newValue is undefined or null
    if (!newValue) {
      return;
    }

    // Find the selected persona from formatted jobTitles
    const selectedPersona = masterData?.jobTitles?.find(
      (p) => p.value === newValue
    );

    console.log('selectedPersona ', selectedPersona);
    console.log('masterData.jobTitles ', masterData?.jobTitles);
    console.log('newValue ', newValue);

    if (!currentJobTitle) {
      // Initial selection
      setAlertType('initial');
      setNewPersona({
        id: selectedPersona?.value,
        title: selectedPersona?.label,
        persona_description: selectedPersona?.description,
      });
      setIsAlertOpen(true);
      setValue('jobTitle', newValue);
      await fetchAndPopulateTemplate(newValue);
    } else if (currentJobTitle !== newValue) {
      // Role change
      // const currentPersonaObj = masterData?.jobTitles?.find(
      //   (p) => p.value === currentJobTitle
      // );

      setAlertType('change');
      setNewPersona({
        id: selectedPersona?.value,
        title: selectedPersona?.label,
        persona_description: selectedPersona?.description,
      });
      setProposedJobTitle(newValue);
      setIsAlertOpen(true);
    }
  };

  const handleAlertClose = () => setIsAlertOpen(false);

  const handleCancel = () => {
    setIsAlertOpen(false);
    setProposedJobTitle(null);
  };

  const handleContinue = async () => {
    if (proposedJobTitle) {
      setValue('jobTitle', proposedJobTitle);
      await fetchAndPopulateTemplate(proposedJobTitle);
    }
    setIsAlertOpen(false);
    setProposedJobTitle(null);
  };

  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formatRequirementData = (formData) => ({
    organization_id: 1,
    created_by_id: 1,
    test_collection_id: 1,
    persona_id: formData.jobTitle,
    business_unit_id: parseInt(formData.businessUnit, 10),
    career_level: formData.careerLevel,
    detailed_job_description: formData.description,
    additional_requirements: formData.additionalInstructions || '',
    minimum_salary: parseInt(formData.minSalary, 10),
    maximum_salary: parseInt(formData.maxSalary, 10),
    employment_type: formData.employmentType,
    work_experience_id: parseInt(formData.minExperience, 10),
    closing_date: formatDate(formData.closingDate),

    qualification_requirements: [
      {
        qualification_type: formData.minEducation,
        field_of_study:
          formData.fieldsOfStudy?.map((field) => ({
            field_of_study_id: parseInt(field.value, 10),
            is_mandatory: field.isMandatory,
          })) || [],
      },
    ],

    locations:
      formData.locations?.map((loc) => ({
        location_id: parseInt(loc.location, 10),
        headcount: parseInt(loc.count, 10),
      })) || [],

    certifications:
      formData.professionalCertifications?.map((cert) => ({
        certification_id: parseInt(cert.value, 10),
        is_mandatory: cert.isMandatory,
      })) || [],

    skills:
      formData.skills?.map((skill) => ({
        skill_id: parseInt(skill.value, 10),
        is_mandatory: skill.isMandatory,
      })) || [],
  });

  const handleNextClick = handleSubmit(
    async (data) => {
      try {
        setIsSubmitting(true);
        setSubmitError(null);

        const updData = formatRequirementData(data);
        let response;

        if (reqId) {
          response = await RequirementService.updateRequirement(updData, reqId);
        } else {
          response = await RequirementService.createRequirement(updData);
        }

        if (response.success) {
          onSubmit({
            ...data,
            requirementId: reqId || response.data.requirement_id,
          });
        } else {
          throw new Error(response.message || 'Failed to create requirement');
        }
      } catch (error) {
        setSubmitError(error.message);
        console.error('Error creating requirement:', error);
      } finally {
        setIsSubmitting(false);
      }
    },
    (errors) => {
      console.log('Validation errors:', errors);
    }
  );

  return {
    form,
    proposedJobTitle,
    setIsAlertOpen,
    isAlertOpen,
    alertType,
    newPersona,
    isSubmitting,
    submitError,
    handleRoleChange,
    handleAlertClose,
    handleCancel,
    handleContinue,
    handleNextClick,
  };
};
