import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

export function Breadcrumb({ items }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {items.map((item) => (
          <li
            key={`${item.href}-${item.label}`}
            className="inline-flex items-center"
          >
            {item.href !== '/' && (
              <ChevronRight className="w-4 h-4 text-gray-400" />
            )}
            {item.href === items[items.length - 1].href ? (
              <span
                className={`text-sm font-medium text-gray-500 ${
                  item.href !== '/' ? 'ml-1 md:ml-2' : ''
                }`}
              >
                {item.label}
              </span>
            ) : (
              <Link
                to={item.href}
                className={`text-sm font-medium ahBlueText hover:ahBlueTextHover ${
                  item.href !== '/' ? 'ml-1 md:ml-2' : ''
                }`}
              >
                {item.label}
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
