import api from '../axios.config';
import { API_ENDPOINTS, createEndpoint } from './endpoints';

const STATUS_MAPPING = {
  shortlisted: ['SHORTLISTED'],
  pending_interview: ['PENDING_INTERVIEW'],
  offer_extended: ['OFFER_EXTENDED'],
  new: ['SUBMITTED'],
  accepted: ['ACCEPTED'],
  rejected: ['REJECTED'],
};

export const CandidateService = {
  getCandidateDetails: async (id) => {
    const endpoint = createEndpoint(
      API_ENDPOINTS.CANDIDATES.GETCANDIDATEDETAILS,
      {
        id,
      }
    );
    const response = await api.get(endpoint);
    return response;
  },

  listCandidates: async (pageNo, pageSize) => {
    const endpoint = createEndpoint(API_ENDPOINTS.CANDIDATES.LIST, {
      pageNo,
      pageSize,
    });
    const response = await api.get(endpoint);
    return response;
  },

  submitCandidates: async (id, data) => {
    const endpoint = createEndpoint(API_ENDPOINTS.CANDIDATES.SUBMITCANDIDATE, {
      id,
    });
    const response = await api.post(endpoint, data);
    return response;
  },

  getCandidatesByStatus: async ({ reqId, status, page, pageSize }) => {
    const mappedStatus = STATUS_MAPPING[status]
      ? STATUS_MAPPING[status][0]
      : status;
    const endpoint = createEndpoint(API_ENDPOINTS.CANDIDATES.LISTBYSTATUS, {
      id: reqId,
      pageNo: page,
      pageSize,
      status: mappedStatus,
    });

    const response = await api.get(endpoint);
    return response;
  },
  DeleteCandidate: async (reqId, data) => {
    const endpoint = createEndpoint(API_ENDPOINTS.CANDIDATES.DELETECANDIDATE, {
      id: reqId,
    });
    const response = await api.delete(endpoint, { data });
    return response;
  },
  updateCandidateStatus: async (reqId, data) => {
    const endpoint = createEndpoint(
      API_ENDPOINTS.CANDIDATES.UPDATECANDIDATESTATUS,
      {
        id: reqId,
      }
    );
    const response = await api.post(endpoint, data);
    return response;
  },
};
