import React from 'react';
import { AlertTriangle } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogCancel,
} from 'src/components/ui/alert-dialog';

export function AlertRoleChange({
  isOpen,
  onClose,
  alertType,
  newPersona,
  onCancel,
  onContinue,
}) {
  const isInitialSelection = alertType === 'initial';

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent className="sm:max-w-lg lg:max-w-3xl">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-xl font-semibold text-gray-900">
            {isInitialSelection
              ? 'Selected Role Details'
              : 'Confirm Role Change'}
          </AlertDialogTitle>
        </AlertDialogHeader>

        <AlertDialogDescription asChild>
          {isInitialSelection ? (
            <div className="space-y-4">
              <div className="p-4 bg-blue-50 rounded-md">
                <div className="text-base font-semibold text-gray-900 mb-2">
                  {newPersona?.title}
                </div>
                <div className="text-base text-gray-700 leading-relaxed">
                  {newPersona?.persona_description}
                </div>
              </div>
            </div>
          ) : (
            <div className="space-y-6">
              <div className="p-4 bg-blue-50 rounded-md">
                <div className="text-base font-semibold text-gray-900 mb-2">
                  {newPersona?.title}
                </div>
                <div className="text-base text-gray-700 leading-relaxed">
                  {newPersona?.persona_description}
                </div>
              </div>

              <div className="flex items-start gap-3 bg-amber-50 border border-amber-200 rounded-lg p-4">
                <AlertTriangle className="h-5 w-5 text-amber-600 mt-0.5 flex-shrink-0" />
                <div className="space-y-1">
                  <div className="text-sm font-medium text-amber-800">
                    Please Review Changes
                  </div>
                  <div className="text-sm text-amber-700">
                    Changing the role will update related fields and
                    requirements. Please verify before continuing.
                  </div>
                </div>
              </div>
            </div>
          )}
        </AlertDialogDescription>

        <AlertDialogFooter className="flex-row gap-3 mt-6">
          {isInitialSelection ? (
            <AlertDialogAction
              onClick={onClose}
              className="bg-blue-600 hover:bg-blue-700 w-full sm:w-auto px-6"
            >
              Continue
            </AlertDialogAction>
          ) : (
            <>
              <AlertDialogCancel
                onClick={() => {
                  onCancel?.();
                  onClose();
                }}
                className="mt-0 px-6"
              >
                Cancel
              </AlertDialogCancel>

              <AlertDialogAction
                onClick={() => {
                  onContinue?.();
                  onClose();
                }}
                className="bg-blue-600 hover:bg-blue-700 px-6"
              >
                Continue
              </AlertDialogAction>
            </>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
