import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FilterButton from 'src/components/common/FilterButton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { Checkbox } from 'src/components/ui/checkbox';
import { Button } from 'src/components/ui/button';
import { format } from 'date-fns';
import { useToast } from 'src/hooks/use-toast';
import { Loader2 } from 'lucide-react';
import { Breadcrumb } from 'src/components/common/Breadcrumb';
import { DataTablePagination } from 'src/components/common/DataTablePagination';
import { CandidateService } from 'src/services/api/CandidateService';
import { RequirementService } from 'src/services/api/RequirementService';
import { Alert } from 'src/components/common/Alert';

const DEFAULT_PAGE_SIZE = 10;

const initialSearchResults = {
  items: [],
  total: 0,
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  totalPages: 1,
};

const ListByFilter = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const reqId = searchParams.get('reqId');
  const { toast } = useToast();

  // State Management
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(
    searchParams.get('status') || 'new'
  );
  const [reqData, setReqData] = useState(null);
  const [searchResults, setSearchResults] = useState({
    ...initialSearchResults,
    page: parseInt(searchParams.get('page') || '1', 10),
    pageSize: parseInt(
      searchParams.get('pageSize') || DEFAULT_PAGE_SIZE.toString(),
      10
    ),
  });

  // Action configurations
  const actionConfigs = {
    new: {
      nextStatus: 'SHORTLISTED',
      buttonText: 'Shortlist Selected',
      confirmTitle: 'Confirm Shortlist',
      confirmMessage: (count) =>
        `Are you sure you want to shortlist ${count} candidate(s)?`,
      successMessage: 'Candidates have been shortlisted successfully',
    },
    shortlisted: {
      nextStatus: 'PENDING_INTERVIEW',
      buttonText: 'Send Interview Invitation',
      confirmTitle: 'Confirm Interview Invitation',
      confirmMessage: (count) =>
        `Are you sure you want to send interview invitation to ${count} candidate(s)?`,
      successMessage: 'Interview invitations have been sent successfully',
    },
  };

  const filters = [
    { label: 'New', value: 'new' },
    { label: 'Shortlisted', value: 'shortlisted' },
    { label: 'Interview', value: 'pending_interview' },
    { label: 'Offers Made', value: 'offer_extended' },
    { label: 'Rejected', value: 'rejected' },
  ];

  const breadcrumbItems = [
    { label: 'Home', href: '/' },
    { label: 'Requirements', href: '/requirements' },
    { label: 'Candidates', href: `/req/${reqId}/candidates` },
  ];
  const fetchCandidates = async () => {
    setLoading(true);
    try {
      const page = parseInt(searchParams.get('page') || '1', 10);
      const pageSize = parseInt(
        searchParams.get('pageSize') || DEFAULT_PAGE_SIZE.toString(),
        10
      );
      const status = searchParams.get('status') || 'new';

      const response = await CandidateService.getCandidatesByStatus({
        reqId,
        status,
        page,
        pageSize,
      });

      if (response.success) {
        const candidatesData = response.data.candidates;
        setCandidates(candidatesData.items || []);
        setSearchResults({
          items: candidatesData.items || [],
          total: candidatesData.total,
          page: candidatesData.page,
          pageSize: candidatesData.page_size,
          totalPages: candidatesData.total_pages,
        });
        setSelectedCandidates([]);
      } else {
        throw new Error(response.message || 'Failed to fetch candidates');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        variant: 'destructive',
      });
      setCandidates([]);
      setSearchResults(initialSearchResults);
    } finally {
      setLoading(false);
    }
  };
  const handleStatusUpdate = async () => {
    if (!confirmAction || selectedCandidates.length === 0) return;

    setUpdating(true);
    try {
      const data = {
        status: confirmAction.nextStatus,
        candidates: selectedCandidates,
      };

      const response = await CandidateService.updateCandidateStatus(
        reqId,
        data
      );

      if (response.success) {
        toast({
          title: 'Success',
          description: confirmAction.successMessage,
        });
        fetchCandidates();
      } else {
        throw new Error(response.message || 'Failed to update status');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        variant: 'destructive',
      });
    } finally {
      setUpdating(false);
      setShowConfirmDialog(false);
      setConfirmAction(null);
    }
  };

  const handleActionClick = (actionType) => {
    setConfirmAction(actionConfigs[actionType]);
    setShowConfirmDialog(true);
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedCandidates(candidates.map((c) => c.candidate_id));
    } else {
      setSelectedCandidates([]);
    }
  };

  const handleSelectCandidate = (candidateId, checked) => {
    if (checked) {
      setSelectedCandidates((prev) => [...prev, candidateId]);
    } else {
      setSelectedCandidates((prev) => prev.filter((id) => id !== candidateId));
    }
  };

  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);
    setSelectedCandidates([]);
    const newParams = new URLSearchParams(searchParams);
    newParams.set('status', filter);
    newParams.set('page', '1');
    setSearchParams(newParams);
  };
  const fetchRequirementData = async () => {
    if (!reqId) return;

    try {
      const response = await RequirementService.getBasicInfo(reqId);
      if (response.success) {
        setReqData(response.data);
      } else {
        throw new Error(
          response.message || 'Failed to fetch requirement details'
        );
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        variant: 'destructive',
      });
    }
  };
  // Effects
  useEffect(() => {
    fetchRequirementData();
  }, [reqId]);

  useEffect(() => {
    if (reqId) {
      fetchCandidates();
    }
  }, [searchParams]);

  const navigateToProfile = (candidateId) => {
    const currentStatus = searchParams.get('status') || 'new';
    navigate(
      `/requirements/candidate/${candidateId}?status=${currentStatus}&reqId=${reqId}`
    );
  };

  const formatDate = (dateString) =>
    format(new Date(dateString), 'dd MMM yyyy');

  const renderActionButton = () => {
    const config = actionConfigs[currentFilter];
    if (!config) return null;

    return (
      <Button
        onClick={() => handleActionClick(currentFilter)}
        disabled={updating || selectedCandidates.length === 0}
        className="bg-[#1aa1cf] hover:bg-[#38b4df] transition-colors"
      >
        {updating && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
        {config.buttonText} ({selectedCandidates.length})
      </Button>
    );
  };

  const renderTableContent = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={7} className="text-center py-8">
            <div className="flex justify-center items-center">
              <Loader2 className="h-6 w-6 animate-spin mr-2" />
              Loading candidates...
            </div>
          </TableCell>
        </TableRow>
      );
    }

    if (candidates.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={7} className="text-center py-8">
            No candidates found
          </TableCell>
        </TableRow>
      );
    }

    const showCheckbox = ['new', 'shortlisted'].includes(currentFilter);

    return candidates.map((candidate) => (
      <TableRow key={candidate?.candidate_id} className="hover:bg-gray-50">
        {showCheckbox && (
          <TableCell>
            <Checkbox
              checked={selectedCandidates.includes(candidate.candidate_id)}
              onCheckedChange={(checked) =>
                handleSelectCandidate(candidate.candidate_id, checked)
              }
            />
          </TableCell>
        )}
        <TableCell
          className="font-medium cursor-pointer"
          onClick={() => navigateToProfile(candidate.candidate_id)}
        >
          {`${candidate.first_name} ${candidate.last_name}`}
        </TableCell>
        <TableCell
          onClick={() => navigateToProfile(candidate.candidate_id)}
          className="cursor-pointer"
        >
          {candidate.email}
        </TableCell>
        <TableCell
          onClick={() => navigateToProfile(candidate.candidate_id)}
          className="cursor-pointer"
        >
          {candidate.phone}
        </TableCell>
        <TableCell
          onClick={() => navigateToProfile(candidate.candidate_id)}
          className="cursor-pointer"
        >
          {candidate.status}
        </TableCell>
        <TableCell
          onClick={() => navigateToProfile(candidate.candidate_id)}
          className="cursor-pointer"
        >
          {candidate.mapped_by_name}
        </TableCell>
        <TableCell
          onClick={() => navigateToProfile(candidate.candidate_id)}
          className="cursor-pointer"
        >
          {formatDate(candidate.mapped_at)}
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <div className="text-left space-y-4">
      <div className="flex items-center justify-between">
        <h1 className="text-lg font-semibold md:text-2xl pageTitle">
          Candidates
        </h1>
      </div>

      <Breadcrumb items={breadcrumbItems} />

      {reqData && (
        <div className="rounded-lg border lightBlueBg text-card-foreground shadow-sm">
          <div className="p-6">
            <h3 className="text-xl font-semibold text-primary">
              {reqData.title}
            </h3>
            <p className="text-sm text-muted-foreground mt-1 font-semibold">
              {reqData.organization}
            </p>
          </div>
        </div>
      )}

      <div className="rounded-lg border shadow-sm">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center gap-4">
              <FilterButton
                filters={filters}
                defaultActiveFilter={currentFilter}
                onFilterChange={handleFilterChange}
              />
              {renderActionButton()}
            </div>
          </div>
          <div className="rounded-md border bg-background">
            <Table>
              <TableHeader>
                <TableRow>
                  {['new', 'shortlisted'].includes(currentFilter) && (
                    <TableHead className="w-12">
                      <Checkbox
                        checked={
                          selectedCandidates.length === candidates.length &&
                          candidates.length > 0
                        }
                        onCheckedChange={handleSelectAll}
                      />
                    </TableHead>
                  )}
                  <TableHead>Name</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Phone</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Updated by</TableHead>
                  <TableHead>Updated on</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>{renderTableContent()}</TableBody>
            </Table>

            {candidates?.length > 0 && (
              <div className="px-4">
                <DataTablePagination
                  currentPage={searchResults.page}
                  pageSize={searchResults.pageSize}
                  totalItems={searchResults.total}
                  onPageChange={() => fetchCandidates()}
                  onPageSizeChange={() => fetchCandidates()}
                  preserveParams
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <Alert
        isOpen={showConfirmDialog}
        onClose={() => {
          setShowConfirmDialog(false);
          setConfirmAction(null);
        }}
        title={confirmAction?.confirmTitle || 'Confirm Action'}
        description={confirmAction?.confirmMessage(selectedCandidates.length)}
        cancelText="Cancel"
        continueText={updating ? 'Processing...' : 'Continue'}
        onCancel={() => {
          setShowConfirmDialog(false);
          setConfirmAction(null);
        }}
        onContinue={handleStatusUpdate}
      />
    </div>
  );
};

export default ListByFilter;
