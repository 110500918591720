import { useNavigate } from 'react-router-dom';
import { UserService } from 'src/services/api/UserService';

export const useAuthActions = () => {
  const navigate = useNavigate();
  const handleAuthError = (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          throw new Error('Invalid credentials');
        case 403:
          throw new Error('Account is locked. Please contact support');
        case 404:
          throw new Error('Account not found');
        case 422:
          throw new Error('Invalid login credentials');
        case 429:
          throw new Error('Too many login attempts. Please try again later');
        default:
          throw new Error(
            error.response.data?.message || 'An error occurred during login'
          );
      }
    } else if (error.request) {
      throw new Error(
        'Unable to connect to the server. Please check your internet connection'
      );
    }
    throw new Error('An unexpected error occurred');
  };
  const logout = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      try {
        const logoutData = {
          refresh_token: refreshToken,
        };
        // Make API call using UserService
        await UserService.logoutUser(logoutData);
      } catch (error) {
        console.error('Login failed:', error);
        throw handleAuthError(error);
      }
    }
    // useEffect(() => {
    //   // Only call logout if there's actually a token to clear
    //   const token = localStorage.getItem('token');
    //   if (token) {
    //     logout().catch((error) => {
    //       console.warn('Error during logout:', error);
    //     });
    //   }
    // }, []);

    // clearAuthUser();
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');

    navigate('/login');
  };

  const processLoginResponse = (data) => {
    const { tokens, user_info: userInfo } = data;

    if (tokens?.access_token) {
      // Store tokens
      localStorage.setItem('token', tokens.access_token);
      if (tokens.refresh_token) {
        localStorage.setItem('refreshToken', tokens.refresh_token);
      }

      // Format user data
      const userData = {
        cid: userInfo.id,
        email: userInfo.email,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        username: userInfo.username,
        emailVerified: userInfo.emailVerified,
        dbSyncStatus: userInfo.db_sync,
        identityProvider: userInfo.identityProvider,
        roles: userInfo.roles,
      };

      // Store user data
      localStorage.setItem('user', JSON.stringify(userData));

      navigate('/requirements');

      return data;
    }

    throw new Error('Invalid token received');
  };

  const login = async (credentials) => {
    try {
      // Clear any existing auth state before attempting new login
      logout();

      // Make API call using UserService
      const response = await UserService.loginUser(credentials);

      // Process the login response
      return processLoginResponse(response.data);
    } catch (error) {
      console.error('Login failed:', error);
      throw handleAuthError(error);
    }
  };
  return {
    login,
    logout,
  };
};
