export const createEndpoint = (template, params = {}) => {
  let endpoint = template;

  // Replace URL parameters (:param)
  Object.entries(params).forEach(([key, value]) => {
    endpoint = endpoint.replace(`:${key}`, value);
  });

  // Replace query parameters ({{param}})
  Object.entries(params).forEach(([key, value]) => {
    const placeholder = `{{${key}}}`;
    if (endpoint.includes(placeholder)) {
      endpoint = endpoint.replace(placeholder, encodeURIComponent(value));
    }
  });

  return endpoint;
};
export const API_ENDPOINTS = {
  AUTH: {
    VERIFY: '/auth/verify',
    REFRESH: '/auth/refresh',
  },
  USER: {
    REGISTER: '/auth/register-candidate',
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    ACTIVATE: '/auth/verify-email/:token',
    SENDOTP: '/candidates/request-email-otp',
    VERIFYOTP: '/candidates/verify-email-otp',
    RESENDOTP: '/candidates/resend-email-otp',
  },
  REQUIREMENT: {
    GETREFERENCEDATA: '/customer/reference-data',
    CREATEREQUIREMENT: '/customer/requirements',
    GETSELECTIONPROCESS: '/customer/requirements/:id/selection-process',
    SAVESELECTIONPROCESS: '/customer/requirements-continue',
    GETREQUIREMENTPREVIEWDATA: '/customer/requirements/:id/preview',
    GETREQUIREMENTSBYSTATUS: '/customer/:id/requirements?status={{status}}',
    SUBMITREQUIREMENT: '/customer/requirements/:id/activate',
    FETCHREQUIREMENTDETAILS: '/customer/requirements/:id',
    UPDATEREQUIREMENT: '/customer/requirements/:id',
    GETPERSONAINFO: '/internal/personas/:id',
    GETBASICREQUIREMENTINFO: '/internal/opportunities/:id',
  },
  CANDIDATES: {
    LIST: '/internal/candidates/list?page={{pageNo}}&page_size={{pageSize}}',
    SUBMITCANDIDATE: '/internal/opportunities/:id/candidates',
    LISTBYSTATUS:
      '/customer/requirements/:id/candidates?status={{status}}&page={{pageNo}}&page_size={{pageSize}}',
    DELETECANDIDATE: '/internal/opportunities/:id/candidates',
    GETCANDIDATEDETAILS: '/candidates/:id/full-profile',
    UPDATECANDIDATESTATUS: '/customer/requirements/:id/bulk-update/candidates',
  },
};
